// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import feather from 'feather-icons/dist/feather.js';
import MicroModal from 'micromodal/dist/micromodal.min.js';

export default class extends Controller {
  connect() {
    feather.replace()
    MicroModal.init()
  }
}
