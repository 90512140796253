import { Controller } from "stimulus"
import Quill from "quill"

export default class extends Controller {
  static targets = ['textarea', 'editor']

  connect() {
    const quill = new Quill(this.editorTarget, {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic'],
          ['link', 'blockquote', 'code-block', 'image'],
          [{ list: 'ordered' }, { list: 'bullet' }]
        ]
      }
    });
    this.textareaTarget.value = quill.root.innerHTML;

    const textareaElement = this.textareaTarget;
    quill.on('text-change', function() {
      let delta = quill.getContents();
      textareaElement.value = quill.root.innerHTML;
    });
  }
}
