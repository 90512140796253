import { Controller } from "stimulus"

export default class extends Controller {
  actionRequest(event) {
    event.preventDefault()
    const token = document.getElementsByName('csrf-token')[0].content
    var obj = event.target
    var parent = obj.parentNode
    var sibling = parent.firstChild
    var siblingClass = sibling.classList[(sibling.classList.length - 1)]
    var url = ''
    var responseClass = ''
    var responseValue = obj.checked
    if (responseValue){
      url = 'add_ability'
      responseClass='green'
    }else{
      url = 'remove_ability'
      responseClass='red'
    }
    var data = {}
    data['ability'] = obj.value
    data['role_id'] = document.getElementById('abilities').dataset['role']

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers:{
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      }
    }).then(function (response) {
      obj.checked = responseValue
      sibling.classList.remove(siblingClass)
      sibling.classList.toggle(responseClass)
    })
    .catch(function (error) {
      console.log(error);
      obj.checked = !responseValue
    })
  }
}
