import { Controller } from "stimulus"
import Choices from "choices.js"

export default class extends Controller {
  static targets = ['campaigns']

  connect() {
    new Choices(this.campaignsTarget)
  }
}
