import { Controller } from "stimulus"
import Choices from "choices.js"
import flatpickr from "flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js"

export default class extends Controller {
  static targets = ['courses', 'users', 'date']

  connect() {
    new Choices(this.coursesTarget)
    new Choices(this.usersTarget)
    flatpickr(this.dateTarget, {
      mode: "range",
      locale: Spanish,
    })
  }
}
